<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{ ...fieldAttributes, options: bases }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  data() {
    return { bases: [] };
  },
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result", "value"],
  computed: {
    connectionId() {
      return this.result.valueLine.connectionId;
    },
  },
  created() {
    this.getBases(this.connectionId);
  },
  watch: {
    "result.valueLine.connectionId": function (newVal) {
      this.getBases(newVal);
    },
  },
  methods: {
    getBases(connectionId) {
      if (
        // this.stepInfo != null &&
        // typeof this.stepInfo.clientServerInfo != "undefined") ||
        connectionId != ""
      ) {
        let server;
        if (
          this.stepInfo != null &&
          typeof this.fieldAttributes.stepInfo.clientServerInfo != "undefined"
        ) {
          let info = this.stepInfo.clientServerInfo[connectionId];
          let bases;
          server = info.serverId;
          if (
            typeof this.fieldAttributes.stepInfo.LDAPServerBases[server] !=
            "undefined"
          ) {
            bases = this.fieldAttributes.stepInfo.LDAPServerBases[server];
            console.log(bases);
            this.bases = JSON.parse(bases).bases;
          }
        } else {
          let caller = this;

          caller.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              function: 'getldapBases',
              // id: server,
              connectionId: connectionId,
            },
          })
              .then(function (post) {
              let bases = post.data.result.string;
              let options = JSON.parse(bases).bases;
              //console.log(options);
              caller.bases = options;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        let bases = {};
        return bases;
      }
    },
  },
};
</script>